<template>
    <v-menu
        :close-on-content-click="false"
        min-width="auto"
        :nudge-bottom="pickerNudgeBottom"
        :nudge-left="pickerNudgeLeft"
        :nudge-right="pickerNudgeRight"
        :nudge-top="pickerNudgeTop"
        offset-y
        transition="scale-transition"
        v-model="menuModel"
    >
        <template v-slot:activator="{ on, attrs }">
            <ValidationProvider
                :name="validationName"
                :rules="validationRules"
                v-slot="{ errors }"
            >
                <v-text-field
                    autocomplete="off"
                    :clearable="inputIsClearable"
                    dense
                    :disabled="inputDisabled"
                    :error-messages="errors"
                    filled
                    hide-details="auto"
                    :hint="inputHint"
                    :id="id"
                    outlined
                    :placeholder="inputPlaceholder"
                    :prepend-icon="inputIcon"
                    :readonly="inputReadOnly"
                    :type="inputType"
                    :value="inputValue"
                    v-bind="attrs"
                    v-on="on"
                    @input.native="$emit('input', $event)"
                />
            </ValidationProvider>
        </template>
        <v-date-picker
            scrollable
            :value="inputValue"
            @change="$emit('change', $event)"
            @input.native="$emit('input', $event)"
        />
    </v-menu>
</template>

<script>
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "Field cannot be empty.",
});

export default {
    data: () => ({
        menuModel: null,
    }),
    methods: {
        sayHi(a, b, c) {
            console.log(a);
            console.log(b);
            console.log(c);
        },
    },
    name: "customDatePickerField",
    props: {
        errors: { default: null },
        id: { default: null, type: String },
        inputDisabled: { default: false, type: Boolean },
        inputHint: { default: null, type: String },
        inputIcon: { default: null, type: String },
        inputIsClearable: { default: false, type: Boolean },
        inputPlaceholder: { default: "Placeholder", type: String },
        inputReadOnly: { default: false, type: Boolean },
        inputType: { default: "text", type: String },
        inputValue: { default: "", type: String },
        pickerNudgeBottom: { default: 5, type: Number },
        pickerNudgeLeft: { default: 0, type: Number },
        pickerNudgeRight: { default: 0, type: Number },
        pickerNudgeTop: { default: 0, type: Number },
        validationName: { default: "ValidationProviderName", type: String },
        validationRules: { default: null, type: String },
    },
    watch: {},
    // Lifecycle hooks.
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {},
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
};
</script>

